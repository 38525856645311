export const references = [
  { link: '/reference/barva/meta-logo.png', title: 'Meta' },
  { link: '/reference/barva/logo_wolt.png', title: 'wolt' },
  { link: '/reference/barva/logo_westinghouse.png', title: 'westinghouse' },
  { link: '/reference/barva/logo_das_2.png', title: 'das' },
  { link: '/reference/barva/amazon.svg', title: 'Amazon' },
  { link: '/reference/barva/stock.png', title: 'Stock Spirits Group' },
  { link: '/reference/barva/logo_novavax.png', title: 'novavax' },

  { link: '/reference/barva/umprum.png', title: 'UMPRUM' },
  { link: '/reference/barva/halla.png', title: 'Halla' },
  { link: '/reference/barva/logo_realmix.png', title: 'RealityMix' },
  { link: '/reference/barva/koberec.jpg', title: 'Můj koberec' },
  { link: '/reference/barva/logo_generaldynamics.png', title: 'generaldynamics' },
  { link: '/reference/barva/logo_skrivanek.png', title: 'skrivanek' },
  { link: '/reference/barva/logo_swappie.png', title: 'swappie' },
  { link: '/reference/barva/logo_es.png', title: 'es' },
  { link: '/reference/barva/Change.png', title: 'Change' },
  { link: '/reference/barva/logo_energytrading.png', title: 'energytrading' },
  { link: '/reference/barva/logo_topstone.png', title: 'topstone' },
  { link: '/reference/barva/algo.png', title: 'Algotech' },
  { link: '/reference/barva/logo_adra.png', title: 'adra' },
  { link: '/reference/barva/logo_hencefort.png', title: 'hencefort' },
  { link: '/reference/barva/logo_defakto.png', title: 'defakto' },
  { link: '/reference/barva/logo_dvur.png', title: 'dvur' },
  { link: '/reference/barva/logo_elis.png', title: 'elis' },
  { link: '/reference/barva/logo_exporeal.png', title: 'exporeal' },
  { link: '/reference/barva/logo_moon.png', title: 'moon' },
  { link: '/reference/barva/logo_praha.png', title: 'praha' },
  { link: '/reference/barva/logo_praha1.png', title: 'praha1' },
  { link: '/reference/barva/logo_scmbd.png', title: 'scmbd' },
  { link: '/reference/barva/jager.png', title: 'Jägermeister' },
  { link: '/reference/barva/lir_2.png', title: 'lir' },
  { link: '/reference/barva/markfest.png', title: 'Marketing festival' },
  { link: '/reference/barva/cus_2.png', title: 'ČUS' },
  { link: '/reference/barva/bechtel.png', title: 'Bechtel' },
  { link: '/reference/barva/logo_eit.png', title: 'eit' },
  { link: '/reference/barva/logo_sanifair.png', title: 'sanifair' },
];
